import React, { useRef, useState, useEffect } from 'react';
import { Modal, Segment, Button, Form, Dimmer, Loader, Dropdown, Image } from 'semantic-ui-react-bpm';
import { useDispatch, useSelector } from 'react-redux';
import { getFieldListAction } from '../../action';
import { v4 as uuid } from 'uuid';
import { IFieldConfig, IOptionValue, IField, ISortEnumConfig, ISortEnumKey, ISortEnumDirection, EnumSelectAllMode, EnumExclusiveMode } from '../../../../component/admin-module/module/users/interface/field';
import { IRootState } from '../../../../reducers';
import SelectableItems from '../../selectable-items';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IReferencedFieldFilterConfig, IDataFitersConfig } from '../../interface/custom-field-detail';
import { ICustomFieldDetailConfig, IDatabaseType } from '../../interface/custom-field-detail-config';
import { IUserFieldPermission } from '../../../../component/admin-module/module/users/interface/user-field-permission';
import { IDataTable } from '../../../../component/admin-module/module/data-tables/reducer';
import { IForm } from '../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IOption } from '../../../interface/option';
import DisplayFieldForm from '../../display-field-from';
import FilterConfiguration from '../../filter-configuration';
import { fieldVisibility, errorIndicator, BPSystemField } from '../../constant';
import { prepareFilterConfig } from '../../utils/prepare-filter-config';
import { generateSourceOption } from '../../utils/generate-source-option';
import { generateInputObject } from '../../utils/generate-input-object';
import { ICustomFieldProps, IDefaultRef } from '../..';
import referenceForm from '../../../field/type/reference-form';
import { ConfirmationMessage } from '../../../general/confirmation-modal';
import { AlertModal } from '../../../general/alert-modal';
import DropdownPopperOption from '../../../../common/general/dropdown-popper-option';
import { icons } from '../../../icons';
import { IConfigName } from '../../../field/type/interface/field-type-object';
import { getSelectedBpStatusListAction } from '../../filter-configuration/action';
import DefaultFieldConfig from '../default-field-config';
import DefaultField from '../../default-value';
import { IEnumFieldConfig } from '../../interface/enum-field-config';
import { formDtoCustomField, userDtoCustomField } from './constants';

export default class EnumFieldConfig extends DefaultFieldConfig {

  name = 'enum';

  Render: React.FC<ICustomFieldProps> = (props) => {
    const initialEnumSort = { key: ISortEnumKey.Id, direction: ISortEnumDirection.Asc };
    const dispatch = useDispatch();

    const companyId = props.companyId;
    const components = { DropdownIndicator: null };

    const { displayFieldList } = useSelector((state: IRootState) => state.customField);
    const { formBuilderField } = useSelector((state: IRootState) => state.formBuilder);
    const { forUpdateDataTableDetails } = useSelector((state: IRootState) => state.dataTable);
    const { userFieldPermissionList } = useSelector((state: IRootState) => state.user);

    const [error, setError] = useState(errorIndicator);
    const [mandatory, setMandatory] = useState(false);
    const [searchable, setSearchable] = useState(false);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<IFieldConfig>({});
    const [inputValue, setInputValue] = useState('');
    const [reuseDeleteOptionInput, setReuseDeleteOptionInput] = useState('');
    const [optionValue, setOptionValue] = useState<IOptionValue[]>([]);
    const [sourceOption, setSourceOption] = useState<IOption[]>([]);
    const [selectedSource, setSelectedSource] = useState('');
    const [displayFieldOption, setDisplayFieldOption] = useState<IOption[]>([]);
    const [selectedDisplayField, setSelectedDisplayField] = useState<string[]>([]);
    const [isMultiselect, setIsMultiselect] = useState(false);
    const [isMultiline, setIsMultiline] = useState(false);
    const [enumDataType, setEnumDataType] = useState('static');
    const [separator, setSeparator] = useState('');
    const [isOpenConfirmationOptions, setIsOpenConfirmationOptions] = useState(false);
    const [fieldPermissionColor, setFieldPermissionColor] = useState('');
    const [openFilterConfiguration, setOpenFilterConfiguration] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [filterConfig, setFilterConfig] = useState({} as IReferencedFieldFilterConfig);
    const [isCreateRecord, setIsCreateRecord] = useState(false);
    const [isStaticSource, setIsStaticSource] = useState(false);
    const [openStaticSourceConfirm, setOpenStaticSourceConfirm] = useState(false);
    const [QRInput, setQRInput] = useState(false);
    const [enumImageField, setEnumImageField] = useState('');
    const [enumGroupField, setEnumGroupField] = useState('');
    const [autopopulate, setAutopopulate] = useState(false);
    const [refreshState, setRefreshState] = useState(1);
    const [sort, setSort] = useState<ISortEnumConfig>(initialEnumSort);
    const [selectAllMode, setSelectAllMode] = useState<EnumSelectAllMode>(EnumSelectAllMode.None);
    const [exclusiveMode, setExclusiveMode] = useState<EnumExclusiveMode>(EnumExclusiveMode.None);
    const [displayFieldFrom, setDisplayFieldFrom] = useState<{
      showReferenceInSource?: boolean;
      showReferenceInDestination?: boolean;
    }>({});
    const [confidential, setConfidential] = useState(false);
    const [uniqueGrouping, setUniqueGrouping] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState('');

    const fieldNameRef = useRef(document.createElement('input'));
    const defaultvalueRef = useRef<IDefaultRef>({});
    const helpRef = useRef(document.createElement('textarea'));

    const triggerDisplayFieldFrom = (name: 'showReferenceInSource' | 'showReferenceInDestination', value: boolean) => {
      const tempDisplayFieldFrom = { ...displayFieldFrom };
      tempDisplayFieldFrom[name] = value;
      setDisplayFieldFrom(tempDisplayFieldFrom);
    }

    const setFieldPermission = (permissionColor: string) => {
      const colorPermission = userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.name === permissionColor);
      const permission = colorPermission.length > 0 ? colorPermission[0].id : undefined;
      return permission;
    }

    const createOption = (label: string) => ({
      id: uuid(),
      label: label,
      value: label
    });

    const removeSelectedField = (value: string) => {
      const tempSelection = [...selectedDisplayField];
      tempSelection.splice(tempSelection.indexOf(value), 1)
      setSelectedDisplayField(tempSelection)
    }

    const setMultiselect = (value: boolean) => {
      if (!defaultvalueRef.current['defaultField']) {
        defaultvalueRef.current['defaultField'] = { value: null };
      }
      if (value) {
        defaultvalueRef.current['defaultField'].value = [];
      } else {
        defaultvalueRef.current['defaultField'].value = '';
      }
      setIsMultiselect(value);

    }

    const setSelectionOfDisplayField = (value: string) => {
      const tempSelection = [...selectedDisplayField];
      tempSelection.push(value);
      setSelectedDisplayField(tempSelection)
    }

    const confirmSetStaticSource = () => {
      setOpenStaticSourceConfirm(false);
      setIsStaticSource(!isStaticSource);
    }

    const setDatatableProperty = (dataType: string) => {
      if (dataType === 'referenced') {
        const tempFieldData = { ...fieldData };
        tempFieldData.configName = IConfigName.Referenced;
        setFieldData(tempFieldData);
        setEnumDataType(dataType)
        setOptionValue([])
      } else {
        const tempFieldData = { ...fieldData };
        tempFieldData.configName = IConfigName.Dropdown;
        setFieldData(tempFieldData);
        setEnumDataType(dataType)
        setOptionValue([])
        setSelectedDisplayField([]);
        setSelectedSource('');
      }
    }

    const setDataSource = (value: string) => {
      const [type, sourceId] = value.split('|||||');

      setEnumDataType(type);
      setSelectedSource(value);
      setDisplayFieldOption([]);
      setSelectedDisplayField([]);
      setFilterConfig({} as IReferencedFieldFilterConfig);
      if (type === 'user') return;
      dispatch(getFieldListAction({
        company: companyId,
        databaseId: type === 'database' ? sourceId : '',
        formId: type === 'document' ? sourceId : '',
        dataType: type,
      }));
      let referencedFieldConfig: IFieldConfig = {
        ...referenceForm.defaultConfig,
        dataType: type,
        ...referenceForm.defaultConfig.data,
        data: {
          companyId: companyId,
          databaseId: type === 'database' ? sourceId : ''
        } as IDatabaseType
      }
      setConfigData(referencedFieldConfig)
    }

    const getFieldList = () => {
      if (props.formType === 'FormBuilder') {
        return formBuilderField;
      } else {
        let tempFieldList: any = [];
        if (forUpdateDataTableDetails.fields) {
          tempFieldList = [...forUpdateDataTableDetails.fields];
          tempFieldList = tempFieldList.map((e: IField) => {
            let tempField: any = { ...e };
            let tempConfig = {}
            if (tempField.inputConfig) {
              tempField.type = tempField.inputConfig.type;
              tempConfig = { ...tempField.inputConfig.config }
              delete tempField.inputConfig
            }
            tempField.config = { ...tempConfig };
            return tempField as ICustomFieldDetail;
          });
        }
        if (props.formType === 'User') {
          return props.currentFieldList || [];
        }
        return tempFieldList as ICustomFieldDetail[];
      }
    }

    const imageFieldOption = () => {
      let ctr = 0;
      let fieldOption: IOption[] = [];
      if (displayFieldList.length > 0) {
        fieldOption = fieldOption.concat(
          displayFieldList.filter(field => field.type !== 'spacer')
            .filter(field => field.type === 'file')
            .map((field: ICustomFieldDetail) => {
              return { key: ctr++, value: `fields.${field.id || ''}`, text: field.label || '' }
            }));
      }
      if (enumDataType === 'form') {
        fieldOption = formDtoCustomField.filter(field => field.type === 'file')
          .map((field: ICustomFieldDetail) => {
            return { key: ctr++, value: field.id || '', text: field.label || '' }
          });
      }
      if (enumDataType === 'user') {
        fieldOption.push({ key: ctr++, value: `avatar`, text: 'Avatar' });
      }
      return fieldOption;
    }

    const groupingFieldOption = () => {
      let ctr = 0;
      let fieldOption: IOption[] = [];
      let allowedConfigs = ['string', 'number', 'autopopulated']
      if (displayFieldList.length > 0) {
        fieldOption = fieldOption.concat(
          displayFieldList.filter(field => field.type !== 'spacer')
            .filter(field => (field.type && allowedConfigs.indexOf(field.type) > -1) || (field.type === 'enum' && field.config && !field.config.multiselect))
            .map((field: ICustomFieldDetail) => {
              return { key: ctr++, value: `fields.${field.id || ''}`, text: field.label || '' }
            }));
      }
      return fieldOption;
    }

    const useDeletedOptions = () => {
      if (configData.data instanceof Array) {
        const newOptionValue = optionValue.map((data: IOptionValue) => {
          if (data.label === reuseDeleteOptionInput) {
            return { ...data, deleted: false }
          }
          return { ...data }
        });
        setOptionValue(newOptionValue)
        setIsOpenConfirmationOptions(false);
        setReuseDeleteOptionInput('');
      }
    }

    const updateOptionDefaultField = (newOption: IOptionValue[]) => {
      let defaultValue: any = null;
      if (defaultvalueRef.current['defaultField']) {
        defaultvalueRef.current['defaultField'].filteredOption = newOption;
        if (defaultvalueRef.current['defaultField'].value) {
          const isMultiple = defaultvalueRef?.current['defaultField']?.value && typeof defaultvalueRef.current['defaultField'].value === 'object';
          defaultValue = isMultiple ? defaultvalueRef.current['defaultField'].value :
            defaultvalueRef.current['defaultField'].value;
          const filterOption = newOption.filter((e: IOptionValue) => {
            return (isMultiple && defaultValue && defaultValue.indexOf(e.id) > -1 && e.deleted === undefined) ||
              (!isMultiple && defaultValue && e.id === defaultValue && e.deleted === undefined);
          });
          if (isMultiple) {
            defaultValue = filterOption.length > 0 ? filterOption.map((e: IOptionValue) => e.id) : null;
          } else {
            defaultValue = filterOption.length > 0 ? filterOption.map((e: IOptionValue) => e.id).toString() : null;
          }
          defaultvalueRef.current['defaultField'].value = defaultValue;
        }
      }
      setRefreshState(refreshState + 1);
    }

    const updateOptionList = () => {
      const newOption = [...optionValue, createOption(inputValue)];
      setInputValue('');
      setOptionValue(newOption);
      updateOptionDefaultField(newOption);
    }

    const cancelDeleteOption = () => {
      setReuseDeleteOptionInput('');
      setInputValue('');
      setIsOpenConfirmationOptions(false);
    }

    const editFieldFilterConfig = (filter: IReferencedFieldFilterConfig) => {
      setFilterConfig(filter);
      setOpenFilterConfiguration(false);
    }

    const showDefaultValue = () => {
      return fieldData.configName !== IConfigName.Referenced
    }

    const handleOptionKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
      if (!inputValue) return;
      if (event.key === 'Enter') {
        if (configData.data instanceof Array) {
          const inputOptionExist = optionValue.filter((data: IOptionValue) => data.label === inputValue);

          if (inputOptionExist.length > 0 && inputOptionExist[0].deleted) {
            setReuseDeleteOptionInput(inputValue);
            setIsOpenConfirmationOptions(true);
          } else if (inputOptionExist.length > 0 && !inputOptionExist[0].deleted) {
            setAlertMessage('Option already exist! Please try other option.');
          } else {
            updateOptionList();
          }
        } else {
          updateOptionList();
        }
        event.preventDefault();
      }
    }

    const handleOptionOnChange = (value: any) => {
      const newOption = optionValue.map((e: IOptionValue) => {
        const valueExist = value ? value.filter((f: IOptionValue) => e.label === f.label) : [];
        if (valueExist.length === 0) {
          return { ...e, deleted: true };
        }
        return e;
      });
      setOptionValue(newOption);
      updateOptionDefaultField(newOption);
    }

    const getSelectedDisplayField = () => {
      return selectedDisplayField.filter(selectedId => {
        const itemOption = displayFieldOption.filter((list: IOption) => selectedId === list.value)
        return itemOption.length > 0;
      })
    }

    const inputObject = () => generateInputObject({
      fieldData,
      configData,
      enumDataType,
      companyId,
      selectedSource,
      selectedDisplayField: getSelectedDisplayField(),
      separator,
      filterConfig,
      formDetail: props.formDetail || {} as IForm,
      optionValue,
      isMultiselect,
      displayFieldList,
      isCreateRecord,
      isStaticSource,
      QRInput,
      imageKeyPath: enumImageField,
      groupingKeyPath: enumGroupField,
      userFieldList: props.userFieldList || [],
      autopopulate,
      sort,
      selectAllMode,
      exclusiveMode,
      displayFieldFrom,
      uniquePerGroup: uniqueGrouping,
      additionalInfo
    })

    const onSubmit = () => {
      const permission = setFieldPermission(fieldPermissionColor || 'red');

      if (validateRequiredFormField()) {
        let defaultValue: any = null;
        if (defaultvalueRef.current['defaultField']) {
          defaultValue = defaultvalueRef.current['defaultField'].value;
          if (fieldData.type === 'number') {
            defaultValue = !!defaultValue ? parseFloat(defaultValue.replace(/,/g, '')) : null;
          } else if (fieldData.type === 'enum' && isMultiselect) {
            defaultValue = defaultValue && defaultValue.length > 0 ? defaultValue : null;
          } else if (fieldData.type === 'bool') {
            // retain value
          } else {
            defaultValue = defaultValue || null;
          }
        }

        let data: IOnCustomFieldSubmitObject = {
          company: companyId,
          data: {
            ...fieldData,
            required: mandatory,
            label: fieldNameRef.current.value,
            defaultValue: defaultValue,
            hint: helpRef.current.value,
            config: inputObject(),
            multiline: isMultiline,
            confidential: confidential,
            permission
          }
        }
        if (props.formType === 'User') {
          data.data.searchable = searchable;
        }
        props.submit(data, props.fieldId || '');
      }
    }

    const validateRequiredFormField = () => {
      let currentError = { ...error };

      if (props.formType === 'User' || props.formType === 'FormBuilder') {
        currentError.fieldname = fieldNameRef.current.value ? false : true;
      }
      if (props.formType === 'FormBuilder' && fieldData.configName === IConfigName.Referenced) {
        currentError.sourceBp = selectedSource ? false : true;
        currentError.displayField = selectedDisplayField.length > 0 ? false : true;
      }
      setError(currentError)
      return !currentError.fieldname
        && !currentError.sourceBp
        && !currentError.maxLength
        && !currentError.minLength
        && !currentError.displayField;
    }

    const optionFromFormDto = (): IOption[] => {
      return formDtoCustomField.map((f, i) => {
        return { key: i, value: f.id || '', text: f.label || '' }
      });
    }

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        data.configName = config.dataType && config.dataType !== 'static' ? IConfigName.Referenced : data.configName;
        setConfigData(config)
        setFieldData(data) 
        fieldNameRef.current.value = data.label || '';
        helpRef.current.value = data.hint || '';
        if (!defaultvalueRef.current['defaultField']) {
          defaultvalueRef.current['defaultField'] = {};
        }
        defaultvalueRef.current['defaultField'].value = data.defaultValue;
        if (data.configName === IConfigName.Referenced) {
          let referenceId = '';
          setSelectedSource('');
          if (config.dataType === 'database' && config.data && config.data.databaseId) {
            referenceId = config.data.databaseId || '';
            setSelectedSource(`database|||||${referenceId}`);
          }
          if (config.dataType === 'document' && config.data && config.data.formId) {
            referenceId = config.data.formId || '';
            setSelectedSource(`document|||||${referenceId}`);
          }
          if (config.dataType === 'user') {
            setSelectedSource('user');
          }
          if (config.dataType === 'form') {
            setSelectedSource('form');
          }
          const tempLabelFormat = config && config.labelFormat ? config.labelFormat.split(`}}${config.separator || ' '}{{`) : [];
          const displayOptionItems = tempLabelFormat.map((item: string) => item.replace(/{/g, '').replace(/}/g, ''));
          setSelectedDisplayField(displayOptionItems);

          setSeparator(config.separator || '');
          setFilterConfig({
            joinCondition: config.dataFiltersJoint === 'and' ? 'and' : 'or',
            condition: config.dataFilters ? config.dataFilters.map((condition: IDataFitersConfig) => {
              return prepareFilterConfig({
                condition,
                formDetailId: props.formDetail ? props.formDetail.id || '' : '',
                referenceId,
              });
            }) : []
          });
        }
        const colorPermission = userFieldPermissionList.filter((permission: IUserFieldPermission) => permission.id === data.permission);
        const permissionColor = colorPermission.length > 0 ? colorPermission[0].name : undefined;
        setFieldPermissionColor(permissionColor || 'red');
        setSearchable(data.searchable ? true : false);
        setMandatory(data.required ? true : false);
        setEnumDataType(config.dataType || 'static')
        setIsMultiline(data.multiline ? true : false)
        setIsMultiselect(config.multiselect || false);
        setIsCreateRecord(config.showCreateBpOrDt ? true : false);
        setIsStaticSource(config.staticSource ? true : false);
        setQRInput(config.qrInput ? true : false);
        setEnumGroupField(config.groupingKeyPath || '');
        setEnumImageField(config.imageKeyPath || '');
        setAutopopulate(config.autopopulate ? true : false);
        setConfidential(data.confidential || false);
        setUniqueGrouping(config.uniquePerGroup || false);
        setAdditionalInfo(config.sublabelKeyPath || '');
        if (config.sort && config.sort.length > 0) {
          let sort = config.sort[0];
          if (sort.key === ISortEnumKey.Created_At) {
            sort = {
              ...sort,
              key: ISortEnumKey.Id,
            }
          }
          setSort(sort);
        }
        if (config.selectAllMode) {
          setSelectAllMode(config.selectAllMode);
        }
        if (config.exclusiveMode) {
          setExclusiveMode(config.exclusiveMode);
        }
        let newOption: IOptionValue[] = [];
        if (config.data instanceof Array) {
          newOption = config.data.map((e: IOptionValue) => {
            e = { ...e };
            e.value = e.label;
            return e;
          })
        }
        setOptionValue(newOption);
        setDisplayFieldFrom({
          showReferenceInDestination: config.showReferenceInDestination,
          showReferenceInSource: config.showReferenceInSource
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    useEffect(() => {
      setSourceOption(generateSourceOption({
        datatable: props.datatable || [],
        publishedForm: props.publishedForm || [],
        formDetail: props.formDetail || {} as IForm | IDataTable,
        datatableDetail: props.datatableDetail,
        formType: props.formType,
        dataType: props.defaultConfig.dataType
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.datatable, props.publishedForm, props.formDetail])

    useEffect(() => {
      let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
      if (companyId && (config.dataType === 'database' || config.dataType === 'document')
        && config.data && (config.data.formId || config.data.databaseId)) {
        dispatch(getFieldListAction({
          company: companyId,
          databaseId: config.data && config.dataType === 'database' ? config.data.databaseId || '' : '',
          formId: config.data && config.dataType === 'document' ? config.data.formId || '' : '',
          dataType: config.dataType || '',
        }));
        if (config.data && config.dataType === 'document') {
          dispatch(getSelectedBpStatusListAction({
            company: companyId,
            formId: config.data.formId || '',
            dataType: 'document'
          }))
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultData.configName, companyId, JSON.stringify(props.defaultConfig)])

    useEffect(() => {
      let ctr = 0;
      let displayOption: IOption[] = [];
      if (enumDataType === 'document') {
        displayOption = displayOption.concat(
          BPSystemField.map((field: { id: string, label: string }) => {
            return { key: ctr++, value: `${field.id || ''}`, text: field.label || '' }
          })
        );
      }
      if (enumDataType === 'user' && props.userFieldList && props.userFieldList.length > 0) {
        displayOption = displayOption.concat(
          [...props.userFieldList, ...userDtoCustomField].map((field: ICustomFieldDetail) => {
            if (field.type === 'date-time') {
              field.id = `${field.id} | DATE('DD, Mon YYYY HH12:MI:SS AM')`
            }
            return { key: ctr++, value: `fields.${field.id || ''}`, text: field.label || '' }
          }));
      }
      if (displayFieldList.length > 0) {
        displayOption = displayOption.concat(
          displayFieldList
            .filter(field => field.type !== 'spacer')
            .map((field: ICustomFieldDetail) => {
              if (field.type === 'date-time') {
                field.id = `${field.id} | DATE('DD, Mon YYYY HH12:MI:SS AM')`
              }
              return { key: ctr++, value: `fields.${field.id || ''}`, text: field.label || '' }
            }));
      }
      if (enumDataType === 'form') {
        displayOption = optionFromFormDto();
      }
      setDisplayFieldOption(displayOption);
    }, [displayFieldList, enumDataType, props.userFieldList]);

    const enumConfigProps: IEnumFieldConfig = {
      configName: fieldData.configName || IConfigName.None,
      errorDisplayField: error.displayField,
      selectedDisplayField: selectedDisplayField,
      displayFieldOption: displayFieldOption,
      setSelectionOfDisplayField: setSelectionOfDisplayField,
      isMultiselect: isMultiselect,
      setIsMultiselect: setMultiselect,
      setSelectedDisplayField: setSelectedDisplayField,
      removeSelectedField: removeSelectedField,
      openFilterConfiguration: setOpenFilterConfiguration,
      fieldData: fieldData,
      formType: props.formType,
      setEnumDataType: setDatatableProperty,
      enumDataType: enumDataType,
      setIsCreateRecord: setIsCreateRecord,
      isCreateRecord: isCreateRecord,
      isStaticSource: isStaticSource,
      triggerStaticSource: () => setOpenStaticSourceConfirm(true),
      QRInput: QRInput,
      setQRInput: setQRInput,
      imageFieldOption: imageFieldOption(),
      groupingFieldOption: groupingFieldOption(),
      setEnumImageField: setEnumImageField,
      setEnumGroupField: setEnumGroupField,
      enumImageField: enumImageField,
      enumGroupField: enumGroupField,
      condition: filterConfig.condition,
      setSeparator: setSeparator,
      separator: separator,
      autopopulate: autopopulate,
      setAutopopulate: setAutopopulate,
      sort: sort,
      setSort: setSort,
      selectAllMode: selectAllMode,
      exclusiveMode: exclusiveMode,
      setExclusiveMode: setExclusiveMode,
      setSelectAllMode: setSelectAllMode,
      displayFieldFrom: displayFieldFrom,
      triggerDisplayFieldFrom: triggerDisplayFieldFrom,
      setConfidential: setConfidential,
      confidential: confidential,
      uniqueGrouping: uniqueGrouping,
      setUniqueGrouping: setUniqueGrouping,
      setAdditionalInfo: setAdditionalInfo,
      additionalInfo: additionalInfo
    }

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='custom-field-modal'
        centered={false}
        closeIcon={props.isViewOnly ?
          <Image id='field-close-img' src={icons.other.circleCancel} />
          : null}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`${fieldData.configName || 'Custom'} Field Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Segment className='borderless' style={{
            marginTop: `${props.formType === 'FormBuilder' ? '-20px' : '2rem'}`
          }}>
            <Form size='big' id='custom-field-form'>
              <div className='header-field'>
                {props.formType === 'User' &&
                  <span className='field-visibility'>
                    Visibility&nbsp;&nbsp;&nbsp;
                    <Dropdown
                      popperContainer={DropdownPopperOption}
                      selection
                      value={fieldPermissionColor || 'red'}
                      options={fieldVisibility}
                      selectOnBlur={false}
                      onChange={(event, target: any) => { setFieldPermissionColor(target.value) }}
                    />
                  </span>
                }
                {(props.formType !== 'FormBuilder') &&
                  <div className="five wide field mandatory-field" onClick={() => { setMandatory(!mandatory) }}>
                    <span>Mandatory</span>
                    <div className="ui fitted toggle checkbox">
                      <input className="hidden" onChange={() => { }} type="checkbox" checked={mandatory} value="" />
                      <label></label>
                    </div>
                  </div>
                }
                {props.formType === 'User' &&
                  <div className="five wide field searchable-field" onClick={() => { setSearchable(!searchable) }}>
                    <span>Searchable</span>
                    <div className="ui fitted toggle checkbox">
                      <input className="hidden" onChange={() => { }} type="checkbox" checked={searchable} value="" />
                      <label></label>
                    </div>
                  </div>
                }
              </div>
              {(props.formType === 'User' || props.formType === 'FormBuilder') &&
                <Form.Group widths='equal'>
                  <div className={`required six wide field ${error.fieldname ? 'error' : ''}`}>
                    <label>Field Name</label>
                    <div className="ui input">
                      <input onChange={() => {
                        error.fieldname = (fieldNameRef.current.value ? false : true)
                      }
                      } ref={fieldNameRef} type="text" />
                    </div>
                  </div>
                </Form.Group>
              }
              <Form.Group widths='equal'>
                <div className="field">
                  <label>Field Info</label>
                  <textarea rows={3} ref={helpRef}></textarea>
                </div>
              </Form.Group>
              {showDefaultValue() &&
                <Form.Group widths='equal'>
                  <DefaultField
                    defaultvalueRef={defaultvalueRef}
                    configData={configData}
                    fieldData={fieldData}
                    inputObject={inputObject}
                    refreshValue={refreshState}
                    showDefaultValue={showDefaultValue()}
                  />
                </Form.Group>
              }
              {fieldData.configName === IConfigName.Referenced &&
                <>
                  <Form.Group widths='equal' className={`required`}>
                    <div className={`field ${error.sourceBp ? 'error' : ''} ${!fieldData.new ? 'disabled' : ''}`}>
                      <label>Select Source</label>
                      <Dropdown
                        className='select-source-dropdown'
                        fluid
                        multiple={false}
                        selection
                        clearable
                        search
                        selectOnBlur={false}
                        value={selectedSource}
                        options={sourceOption}
                        onChange={(event, target) => {
                          setDataSource(target.value as string)
                        }}
                      />
                    </div>
                  </Form.Group>
                  <DisplayFieldForm {...enumConfigProps} />
                </>
              }
              {fieldData.configName !== IConfigName.Referenced &&
                <SelectableItems
                  {...enumConfigProps}
                  components={components}
                  inputValue={inputValue}
                  handleOptionInputChange={(value: string) => setInputValue(value)}
                  handleOptionKeyDown={handleOptionKeyDown}
                  optionValue={optionValue}
                  handleOptionOnChange={handleOptionOnChange}
                  searchable={searchable}
                />
              }
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions className={`borderless`} id='delete-custom-field-modal-footer'>
          {!props.isViewOnly && <React.Fragment>
            <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
            {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
              <Button color='red' className='btn-action create-btn'
                onClick={() => {
                  props.cancel(props.fieldId, true)
                  props.openDeleteModal(props.fieldId, fieldData.label || '');
                }}>Delete</Button>
            }
            {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
              <Button color='green' className='btn-action' onClick={() => onSubmit()}>{props.fieldId ? 'Update' : 'Create'}</Button>
            }
          </React.Fragment>}
        </Modal.Actions>
      </Modal>

      {props.fieldId && openFilterConfiguration &&
        <FilterConfiguration
          configData={configData}
          fieldData={fieldData}
          open={openFilterConfiguration}
          closeFilterModal={() => setOpenFilterConfiguration(false)}
          currentBp={props.formDetail || {} as IForm | IDataTable}
          publishedForm={props.publishedForm || []}
          datatable={props.datatable || []}
          updateFieldFilterConfig={editFieldFilterConfig}
          filterConfig={filterConfig}
          fieldId={props.fieldId}
          fieldList={getFieldList()}
          tableId={props.tableId}
          tableFieldList={props.tableFieldList}
          userFieldList={props.userFieldList}
        />
      }
      <ConfirmationMessage
        confirmButtonName={'Yes'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => cancelDeleteOption()}
        open={isOpenConfirmationOptions}
        confirm={useDeletedOptions}
        cancel={() => cancelDeleteOption()}
        headerText="Confirmation"
        detailText={`Would you like to restore deleted item '${reuseDeleteOptionInput}'?`}
        loading={false}
        type='warning'
      />
      <ConfirmationMessage
        confirmButtonName={'Yes'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={() => setOpenStaticSourceConfirm(false)}
        open={openStaticSourceConfirm}
        confirm={confirmSetStaticSource}
        cancel={() => setOpenStaticSourceConfirm(false)}
        headerText="Confirmation"
        detailText={
          isStaticSource
            ? `When Static config is deactivated, past documents created for this field will be impacted if source is changed . Do you want to proceed?`
            : `When Static config is activated, past documents created will not be impacted even if source is changed. New source will only reflect on new documents moving forward. Do you want to proceed?`
        }
        loading={false}
        type='warning'
      />
      <AlertModal
        open={alertMessage !== ''}
        close={() => setAlertMessage('')}
        message={alertMessage}
        type='error'
      />
    </>
  };

}


