import React, { useRef, useState, useEffect } from 'react';
import DefaultFieldConfig from '../default-field-config';
import { Modal, Dimmer, Loader, Segment, Button, Form, Image, Dropdown } from 'semantic-ui-react-bpm';
import ColumnField from './column-field';
import { icons } from '../../../icons';
import { ICustomFieldDetail, IOnCustomFieldSubmitObject, IConfig, IDataFitersConfig, TableColumnStyle, TableColumnAlignment } from '../../interface/custom-field-detail';
import { IConfigName, IFieldTypeObj } from '../../../field/type/interface/field-type-object';
import { tableFieldType } from '../../../field/type';
import { v4 as uuid } from 'uuid';
import { ConfirmationMessage } from '../../../general/confirmation-modal';
import FormBuilderCustomField from '../../../../component/admin-module/module/business-process/module/form-builder/custom-field';
import { IForm } from '../../../../component/admin-module/module/business-process/module/form/interface/form';
import { ICustomFieldDetailConfig } from '../../interface/custom-field-detail-config';
import { plainToClass } from 'class-transformer';
import { ICustomFieldProps } from '../..';
import { IDataTable } from '../../../../component/admin-module/module/data-tables/reducer';
import referenceField from '../../../field/type/reference-form';
import autopopulatedField from '../../../field/type/autopopulated';
import DropdownPopperOption from '../../../../common/general/dropdown-popper-option';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../reducers';
import { CheckFieldPermission } from '../../../../component/admin-module/module/business-process/module/form-builder/utils/check-field-permission';
import { tableColumnWidthConstraints } from '../../constant';

export default class TableFieldConfig extends DefaultFieldConfig {

  name = 'table';

  Render: React.FC<ICustomFieldProps> = (props) => {
    const defaultError: { columns: boolean, errorIndexes?: number[] } = { columns: false };

    const { permissionList } = useSelector((state: IRootState) => state.permission);

    const companyId = props.companyId;
    const fieldNameRef = useRef(document.createElement('input'));
    const infoRef = useRef(document.createElement('input'));
    const minRecordRef = useRef(document.createElement('input'));
    const maxRecordRef = useRef(document.createElement('input'));

    const [hasSubtotal, setHasSubtotal] = useState(false);
    const [tableType, setTableType] = useState<'horizontal' | 'vertical'>('vertical');
    const [type, setType] = useState<'fixed' | 'dynamic' | 'automatic'>('fixed');
    const [selectedField, setSelectedField] = useState<(ICustomFieldDetail | null)[]>([]);
    const [updatedCurrentFieldList, setUpdatedCurrentFieldList] = useState<ICustomFieldDetail[]>([]);
    const [forDeleteField, setForDeleteField] = useState<{ fieldIndex: number } | null>(null);
    const [selectedCustomField, setSelectedCustomField] = useState<{ field: ICustomFieldDetail, index: number } | null>(null);
    const [fieldData, setFieldData] = useState<ICustomFieldDetail>({} as ICustomFieldDetail);
    const [configData, setConfigData] = useState<ICustomFieldDetailConfig>({});
    const [hasDefaultValue, setHasDefaultValue] = useState(false);
    const [preFillColumnFields, setpreFillColumnFields] = useState<ICustomFieldDetail[]>([]);
    const [uniqueColumnIds, setUniqueColumnIds] = useState([] as string[]);
    const [isConsolidateTable, setIsConsolidateTable] = useState(false);
    const [error, setError] = useState(defaultError);
    const [confidential, setConfidential] = useState(false);
    const [hasColStyle, setHasColStyle] = useState(false); 

    const triggerDefaultValue = () => {
      setHasDefaultValue(!hasDefaultValue);
      setpreFillColumnFields([]);
    }

    const addNewField = () => {
      const tempSelectedField = [...selectedField];
      tempSelectedField.push(null);
      setSelectedField(tempSelectedField);
    }

    const selectField = (configName: IConfigName, index: number) => {
      const tempSelectedField = [...selectedField];
      const field = tableFieldType.filter((field: IFieldTypeObj) => field.defaultData.configName === configName);
      if (field.length > 0) {
        const defaultData = field[0].defaultData as ICustomFieldDetail;
        const customField: ICustomFieldDetail = {
          id: uuid(),
          config: { ...field[0].defaultConfig }, ...defaultData
        }
        tempSelectedField.splice(index, 1, customField);
        setSelectedField(tempSelectedField);
      }
    }

    const setColumnStyles = (clear: boolean ) => { 
      let tempSelectedField = [...selectedField];
      tempSelectedField = tempSelectedField.map(e => {
        const style = e && e.configName   ? {
          alignment: TableColumnAlignment.left,
        } as TableColumnStyle : undefined;
        return { ...e, style: !clear ? style : undefined } as ICustomFieldDetail;
      }); 
      setSelectedField(tempSelectedField);
    }

    const selectReferenceField = (data: string, index: number) => {
      if (data === 'autopopulated') {
        setAutopopulatedConfig(data, index)
      } else {
        setReferencedConfig(data, index);
      }
    }

    const setAutopopulatedConfig = (data: string, index: number) => {
      const tempSelectedField = [...selectedField];
      const field = { ...autopopulatedField }
      const defaultData = field.defaultData as ICustomFieldDetail;
      const customField: any = {
        id: uuid(),
        config: { ...field.defaultConfig },
        ...defaultData
      }
      tempSelectedField.splice(index, 1, customField);
      setSelectedField(tempSelectedField);
    }

    const setReferencedConfig = (data: string, index: number) => {
      const [dataType, sourceId, name] = data.split('|||||');

      let configData = {
        dataType: dataType,
        dataFilters: [] as IDataFitersConfig[],
        dataFiltersJoint: '',
        data: {}
      };

      if (dataType === 'document') {
        configData.data = {
          formId: sourceId,
          company: companyId
        }
      }
      if (dataType === 'database') {
        configData.data = {
          databaseId: sourceId,
          company: companyId
        }
      }

      const tempSelectedField = [...selectedField];
      const field = { ...referenceField }
      const defaultData = field.defaultData as ICustomFieldDetail;
      const customField: any = {
        id: uuid(),
        label: name,
        config: { ...field.defaultConfig, ...configData },
        ...defaultData
      }
      tempSelectedField.splice(index, 1, customField);
      setSelectedField(tempSelectedField);
    }

    const deleteField = (index: number, seq: number | string) => {
      const tempError = { ...error };
      error.columns = false;
      if (tempError.errorIndexes !== undefined) {
        tempError.errorIndexes = tempError.errorIndexes.filter(e => e !== index);
      }
      setError(error);
      setForDeleteField({ fieldIndex: index });

    }

    const changeFieldSequence = (field: ICustomFieldDetail | null, dragIndex: number, dropIndex: number) => {
      const tempSelectedField = [...selectedField];
      tempSelectedField.splice(dragIndex, 1);
      tempSelectedField.splice(dropIndex, 0, field);
      setSelectedField(tempSelectedField);
    }

    const closeDeleteModal = () => {
      setForDeleteField(null);
    }

    const confirmDelete = () => {
      let forDeleteIndex = forDeleteField !== null ? forDeleteField.fieldIndex : -1;
      if (forDeleteIndex > -1) {
        const tempSelectedField = [...selectedField];
        const forDeleteFieldData = tempSelectedField.find((e, i) => i === forDeleteIndex);
        tempSelectedField.splice(forDeleteIndex, 1);
        setSelectedField(tempSelectedField);
        setForDeleteField(null);
        if (forDeleteFieldData) {
          const isExistsOnPrefill = preFillColumnFields.indexOf(forDeleteFieldData);
          if (isExistsOnPrefill > -1) {
            const temppreFillColumnFields = [...preFillColumnFields];
            temppreFillColumnFields.splice(isExistsOnPrefill, 1);
            setpreFillColumnFields(temppreFillColumnFields);
          }
        }
      }
    }

    const editTableColumnField = (field: ICustomFieldDetail, index: number) => {
      setSelectedCustomField({ field, index })
    }

    const updateDetailList = (data: ICustomFieldDetail, fieldId: string) => {
      if (selectedCustomField) {
        data.id = fieldId;
        const tempSelectedField = [...selectedField];
        tempSelectedField.splice(selectedCustomField.index, 1, data);
        setSelectedField(tempSelectedField);
        setSelectedCustomField(null)
      }
    }

    const updateColumnFieldaggregate = (key: string, fieldid: string, value: any) => {
      const tempSelectedField = selectedField.map((field: ICustomFieldDetail | null) => {
        const tempField: any = field ? { ...field } : null;
        if (tempField && tempField.hasOwnProperty('id') && fieldid === tempField.id) {
          tempField[key] = value || undefined; 
        }
        return tempField;
      })
      setSelectedField(tempSelectedField);
    }


    const isValidToSubmit = () => {
      const columns = selectedField as ICustomFieldDetail[];

      let currentError = { ...error };
      currentError.columns = columns.length === 0;

      if (columns.length > 0) {
        const emptyIndexes = columns.filter(col => col === null).map((e, i) => i);
        if (emptyIndexes.length > 0) {
          currentError.errorIndexes = emptyIndexes;
          currentError.columns = true;
        }
      }

      setError(currentError);
      return !currentError.columns && currentError.errorIndexes === undefined;
    }

    const updateTable = () => {
      if (!isValidToSubmit()) return;

      const config = plainToClass(IConfig, {
        ...configData,
        columns: (selectedField as ICustomFieldDetail[]).map(e => {
          const constraints = tableColumnWidthConstraints[e.configName || ''];
          let style = e.style;
          if((style && !style.width) || constraints) {
            style = undefined;
          }
          return { ...e, style: style };
        }),
        minRows: type === 'automatic' ? undefined :
          (
            type === 'fixed'
              ? parseFloat(maxRecordRef.current.value)
              : parseFloat(minRecordRef.current.value)
          ),
        maxRows: type === 'automatic' ? undefined : parseFloat(maxRecordRef.current.value),
        tableType: tableType,
        prefillColumnIds: preFillColumnFields.length > 0 ? preFillColumnFields.map(e => e.id) : undefined,
        uniqueColumnIds: uniqueColumnIds.length > 0 ? uniqueColumnIds : undefined,
        automatic: type === 'automatic' ? true : undefined,
      });

      let data: IOnCustomFieldSubmitObject = {
        company: companyId,
        data: {
          ...fieldData,
          required: false,
          label: fieldNameRef.current.value || 'Untitled',
          hint: infoRef.current.value,
          config: config,
          confidential: confidential,
        }
      }

      props.submit(data, props.fieldId || '');
    }

    const preventNegativeInput = (e: any) => {
      let currentVal = e.target.value;
      if (currentVal.substring(0, 1) === '0' && (e.which === 48 || e.which === 96)) {
        currentVal = currentVal.substring(1);
      }
      if (currentVal.substring(0, 1) === '-' && e.key === '-') {
        currentVal = currentVal.substring(1);
      }
      e.target.value = currentVal;
    };

    const preventPasteNegative = (e: any) => {
      const clipboardData = e.clipboardData;
      const pastedData = parseFloat(clipboardData.getData('text'));
      if (pastedData < 1) {
        e.preventDefault();
      }
    };

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        let data = { ...props.defaultData } as ICustomFieldDetail;
        delete data.config;
        delete data.id;
        if (data.label === 'Untitled') data.label = '';
        let config = { ...props.defaultConfig } as ICustomFieldDetailConfig;
        setConfigData(config);
        setFieldData(data);
        fieldNameRef.current.value = data.label || '';
        infoRef.current.value = data.hint || '';
        maxRecordRef.current.value = config.maxRows?.toString() || '1';
        minRecordRef.current.value = config.minRows?.toString() || '1';
        let recordCountType: any = 'fixed';
        if ((config.minRows?.toString() || '1') !== (config.maxRows?.toString() || '1')) {
          recordCountType = 'dynamic';
        }
        if (config.automatic) {
          recordCountType = 'automatic';
        }
        setType(recordCountType)
        setTableType(config.tableType || 'vertical');
        setUniqueColumnIds(config.uniqueColumnIds || []);
        setConfidential(data.confidential || false);
        if (config.prefillColumnIds && config.prefillColumnIds.length > 0) {
          setHasDefaultValue(true);
          const tempPrefillColumnFields = config.prefillColumnIds.map(fieldId => {
            const fieldData = config.columns && config.columns.find(e => e.id === fieldId);
            if (fieldData) {
              return fieldData;
            }
            return fieldId;
          });
          setpreFillColumnFields(tempPrefillColumnFields as ICustomFieldDetail[]);
        }
        setSelectedField(config.columns || []);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultConfig, props.defaultData])

    useEffect(() => {
      if (Object.keys(props.defaultData).length > 0) {
        if (permissionList.length > 0) {
          const isConsolidate = CheckFieldPermission.isConsolidateTable(permissionList, props.defaultData.id || '');
          if (isConsolidate) {
            setType('automatic');
          }
          setIsConsolidateTable(isConsolidate);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultData, permissionList])

    useEffect(() => {
      const tempUpdatedCurrentField = props.currentFieldList
        ? [...props.currentFieldList.map((e: ICustomFieldDetail) => {
          const f = { ...e }
          if (e.id === props.fieldId) {
            f.config = e.config ? { ...e.config } : {} as IConfig;
            const temptableColumn: any = selectedField.filter((field: ICustomFieldDetail | null) => field !== null);
            f.config.columns = temptableColumn.length > 0 ? temptableColumn as ICustomFieldDetail[] : [] as ICustomFieldDetail[];
          }
          return f;
        })]
        : []
      setUpdatedCurrentFieldList(tempUpdatedCurrentField)
    }, [selectedField, props.currentFieldList, props.fieldId])

    useEffect(() => {
      if (!hasSubtotal) {
        let temphasSubtotal = false;
        let filterWithAgg = selectedField.filter((field: ICustomFieldDetail | null) => field && field.hasOwnProperty('agg') && field.agg);
        if (filterWithAgg.length > 0) {
          temphasSubtotal = true;
        }
        setHasSubtotal(temphasSubtotal);
      }
    }, [hasSubtotal, selectedField]);

    useEffect(() => { 
        let fieldsWithStyle = selectedField.filter((field: ICustomFieldDetail | null) => field && field.style !== undefined);  
        setHasColStyle(fieldsWithStyle.length > 0); 
    }, [ selectedField]);

    return <>
      <Modal
        dimmer='blurring'
        size='large'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={props.fieldId ? true : props.open}
        onClose={() => props.close()}
        id='table-field-modal'
        centered={false}
        closeIcon={props.isViewOnly ?
          <Image id='field-close-img' src={icons.other.circleCancel} />
          : null}
      >
        {
          (props.loading) &&
          <Dimmer active inverted>
            <Loader active content='Loading' />
          </Dimmer>
        }
        <Modal.Header className='borderless' id='custom-field-modal-header' >
          <Segment className={`borderless bg-primary`}>
            <h4>{`Table Setup`}</h4>
          </Segment>
        </Modal.Header>
        <Modal.Content>
          <Form size='big' id='custom-field-form'>
            <Form.Group widths='equal'>
              <div className={`six wide field`}>
                <label>Field Name</label>
                <div className="ui input">
                  <input ref={fieldNameRef} type="text" />
                </div>
              </div>
              <div className=" six wide field">
                <label>Field Info</label>
                <div className="ui input">
                  <input ref={infoRef} type="text" />
                </div>
              </div>
            </Form.Group>
            <Form.Group widths='equal'>
              <div className={`required six wide field table-type-selection`}>
                <label className='title'>Table Type</label>
                <div className="ui input">
                  <input type='radio' onChange={() => { }} checked={tableType === 'vertical'} onClick={() => setTableType('vertical')} /> <Image src={icons.blue.columnHeader} />
                  <input type='radio' onChange={() => { }} checked={tableType === 'horizontal'} onClick={() => setTableType('horizontal')} /> <Image src={icons.blue.rowHeader} />
                </div>
                <div className='table-checkbox-config'>
                  <div className="ui fitted checkbox default-value" onClick={triggerDefaultValue}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={hasDefaultValue} />
                    <label>Default Value</label>
                  </div>
                  <div className="ui fitted checkbox default-value" onClick={() => setConfidential(!confidential)}>
                    <input className="hidden" type="checkbox" onChange={() => { }} checked={confidential} />
                    <label>Confidential</label>
                  </div>
                </div>
              </div>
              <div className={`six wide field`}>
                <label></label>
                <div className="ui input" style={{ display: 'block' }}>
                  <Form.Group widths='equal'>
                    <div className="type-selection">
                      <div className={`type-section`}>
                        <label>Type</label>
                        <div>
                          <Dropdown
                            fluid
                            search
                            selection
                            clearable
                            value={type}
                            placeholder={'Type'}
                            selectOnBlur={false}
                            disabled={isConsolidateTable}
                            popperContainer={DropdownPopperOption}
                            options={[
                              { key: 0, text: 'Fixed', value: 'fixed' },
                              { key: 1, text: 'Dynamic', value: 'dynamic' },
                              { key: 2, text: 'Automatic', value: 'automatic' },
                            ]}
                            onChange={(event, target) => { setType(target.value as 'fixed' | 'dynamic' | 'automatic') }}
                          />
                        </div>
                      </div>

                    </div>
                    <div className={`min-max-record type-section`} style={{ display: `${type === 'automatic' ? 'none' : ''}` }}>
                      <label># of items</label>
                      <div className="ui input">
                        <div style={{ display: `${type === 'dynamic' ? '' : 'none'}` }}>
                          <input min='1' type="number" onPaste={preventPasteNegative} onKeyUp={preventNegativeInput} ref={minRecordRef} /> &nbsp;&nbsp;min
                        </div>
                        <input type="number" min='1' onPaste={preventPasteNegative} onKeyUp={preventNegativeInput} ref={maxRecordRef} /> &nbsp;&nbsp;max
                      </div>
                    </div>
                  </Form.Group>
                  <div className='checkbox-config'>
                    <div className="ui fitted checkbox" onClick={() => setHasSubtotal(!hasSubtotal)}>
                      <input className="hidden" type="checkbox" onChange={() => { }} checked={hasSubtotal} />
                      <label>Sub Totals</label>
                    </div>
                    <div className="ui fitted checkbox" onClick={() => {
                       setColumnStyles(hasColStyle); 
                    }}>
                      <input className="hidden" type="checkbox" onChange={() => { }} checked={hasColStyle} />
                      <label>Column Styles</label>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form>
          <hr />
          <ColumnField
            selectedField={selectedField}
            tableType={tableType}
            addNewField={addNewField}
            selectField={selectField}
            selectReferenceField={selectReferenceField}
            deleteField={deleteField}
            changeFieldSequence={changeFieldSequence}
            editTableColumnField={editTableColumnField}
            publishForm={props.publishedForm || []}
            datatable={props.datatable || []}
            hasSubtotal={hasSubtotal}
            updateColumnFieldaggregate={updateColumnFieldaggregate}
            setpreFillColumnFields={setpreFillColumnFields}
            preFillColumnFields={preFillColumnFields}
            hasDefaultValue={hasDefaultValue}
            uniqueColumnIds={uniqueColumnIds}
            setUniqueColumnIds={setUniqueColumnIds}
            error={error}
            setError={setError}
            formDetail={props.formDetail || {} as IForm | IDataTable}
            hasColStyle={hasColStyle}
          />
        </Modal.Content>
        <Modal.Actions className='borderless' id='delete-custom-field-modal-footer'>
          {!props.isViewOnly && <React.Fragment  >
            <Button color='grey' className='btn-action' onClick={() => props.cancel(props.fieldId)}>{'Cancel'}</Button>
            {((!fieldData.flags || (fieldData.flags && fieldData.flags.indexOf('constraint') === -1)) && props.fieldId && (props.formType === 'User')) &&
              <Button color='red' className='btn-action create-btn'
                onClick={() => {
                  props.cancel(props.fieldId)
                  props.openDeleteModal(props.fieldId, fieldData.label || '');
                }}>Delete</Button>
            }
            {((fieldData.flags && fieldData.flags.indexOf('constraint') === -1) || !fieldData.flags) &&
              <Button color='green' className='btn-action' onClick={() => { updateTable() }}>{props.fieldId ? 'Update' : 'Create'}</Button>
            }
          </React.Fragment>}
        </Modal.Actions>
      </Modal>
      <ConfirmationMessage
        errorMessage={''}
        confirmButtonName={'Confirm'}
        confirmButtonColor={'green'}
        cancelButtonColor={'grey'}
        close={closeDeleteModal}
        open={forDeleteField !== null}
        confirm={confirmDelete}
        cancel={closeDeleteModal}
        headerText="Confirmation"
        detailText={`Are you sure you want to delete the data table column and its data??`}
        loading={false}
        type='warning'
      />
      <FormBuilderCustomField
        isCustomFieldFormOpen={selectedCustomField !== null}
        closeCustomField={() => setSelectedCustomField(null)}
        field={selectedCustomField ? selectedCustomField.field : {} as ICustomFieldDetail}
        updateDetailList={updateDetailList}
        publishedForm={props.publishedForm || []}
        datatable={props.datatable || []}
        formDetail={props.formDetail || {} as IForm | IDataTable}
        forceNoMultiline={true}
        companyId={companyId}
        currentFieldList={updatedCurrentFieldList}
        tableId={props.defaultData.id}
        tableFieldList={(selectedField as ICustomFieldDetail[]) || []}
        userFieldList={props.userFieldList}
      />
    </>
  }
}