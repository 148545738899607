import React from 'react';
import { icons } from '../../../icons';
import { Image, Dropdown } from 'semantic-ui-react-bpm';
import { tableFieldType } from '../../../field/type';
import { IConfigName, IFieldTypeObj } from '../../../field/type/interface/field-type-object';
import { ICustomFieldDetail, TableColumnAlignment } from '../../interface/custom-field-detail';
import { useDrop, useDrag, DragSourceMonitor } from 'react-dnd';
import { IForm } from '../../../../component/admin-module/module/business-process/module/form/interface/form';
import { IDataTable } from '../../../../component/admin-module/module/data-tables/reducer';
import DropdownPopperOption from '../../../../common/general/dropdown-popper-option';
import { arrayOperator, tableColumnWidthConstraints } from '../../constant';

interface IFieldProps {
  fieldIndex: number;
  field: ICustomFieldDetail;
  deleteField(index: number, seq?: number | string): void;
  changeFieldSequence(field: ICustomFieldDetail | null, dragIndex: number, dropIndex: number): void;
  editTableColumnField(field: ICustomFieldDetail, index: number): void;
  publishForm: IForm[];
  datatable: IDataTable[];
  selectReferenceField(data: string, index: number): void;
  hasSubtotal: boolean;
  updateColumnFieldaggregate(key: string, fieldid: string, value: any): void;
  hasReferenceField: boolean;
  setpreFillColumnFields(value: ICustomFieldDetail[]): void;
  preFillColumnFields: ICustomFieldDetail[];
  hasDefaultValue: boolean;
  hasColStyle: boolean;
}

const Field: React.FC<IFieldProps> = (props) => {
  // eslint-disable-next-line
  const [collectedProps, dropRef] = useDrop({
    accept: 'tableSelectedField',
    drop: selectedItem => {
      const data: any = selectedItem;
      props.changeFieldSequence(data.field, data.index, props.fieldIndex)
    }
  })

  const [draggedObject, dragRef] = useDrag({
    item: { type: 'tableSelectedField', field: props.field, index: props.fieldIndex },
    collect: (monitor: DragSourceMonitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      }
    },
  });

  const aggregateOptions = (): any => {
    if (props.hasSubtotal && (props.field.type === 'fx' || props.field.type === 'number')) {
      return <Dropdown
        popperContainer={DropdownPopperOption}
        fluid
        search
        selection
        clearable
        className='aggregate-options'
        value={props.field.agg}
        selectOnBlur={false}
        placeholder={'Selected Value'}
        options={arrayOperator}
        onChange={(event, target) => {
          props.field.id && props.updateColumnFieldaggregate(
            'agg',
            props.field.id,
            target.value as string,
          )
        }}
      />
    }
    return null;
  }

  const minWidthInput = (): any => { 
    if (props.hasColStyle) {
      return <div className="ui input">
        <input
          type="number"
          placeholder='min width'
          className='min-width'
          onBlur={(e) => {
            props.field.id && props.updateColumnFieldaggregate(
              'style',
              props.field.id,
              {
                ...props.field.style,
                width: parseFloat(e.target.value),
              },
            )
          }}
          onChange={() => { }}
          ref={(ref) => {
            if(ref) {
                if (props.field.style?.width) {
              ref.value = props.field.style?.width.toString();
            }
            const widthConstraints = tableColumnWidthConstraints[props.field.configName!];
              if(widthConstraints) { 
                ref.value = widthConstraints.toString();
                ref.disabled = true;
              }
            }  
          }}
        />
      </div>
    }
    return null;
  }

  const alignmentSelection = (): any => {
    if (props.hasColStyle) {
    return <div id="collapse-button-group" style={{marginLeft: '10px'}}>
       <div className="ui icon buttons" style={{}}>
        <label>Alignment</label>
        <button className={`ui button ${props.field.style?.alignment === TableColumnAlignment.left ? 'active' : ''}`}
          onClick={() => { props.field.id && props.updateColumnFieldaggregate(
            'style',
            props.field.id,
            {
              ...props.field.style,
              alignment: TableColumnAlignment.left,
            },
          )}}>
          <i aria-hidden="true" className="align left icon"></i>
        </button>
        <button className={`ui button ${props.field.style?.alignment === TableColumnAlignment.center ? 'active' : ''}`}
         onClick={() => { props.field.id && props.updateColumnFieldaggregate(
          'style',
          props.field.id,
          {
            ...props.field.style,
            alignment: TableColumnAlignment.center,
          },
        )}}>
          <i aria-hidden="true" className="align center icon"></i>
        </button>
        <button className={`ui button ${props.field.style?.alignment === TableColumnAlignment.right ? 'active' : ''}`}
         onClick={() => { props.field.id && props.updateColumnFieldaggregate(
          'style',
          props.field.id,
          {
            ...props.field.style,
            alignment: TableColumnAlignment.right,
          },
        )}}>
          <i aria-hidden="true" className="align right icon"></i>
        </button>
      </div>
      </div>;
    }
    return null;
  }

  const setpreFillColumnFields = (field: ICustomFieldDetail) => {
    const temppreFillColumnFields = [...props.preFillColumnFields];
    const index = temppreFillColumnFields.indexOf(field);
    if (index === -1) {
      temppreFillColumnFields.push(field);
    } else {
      temppreFillColumnFields.splice(index, 1);
    }
    props.setpreFillColumnFields(temppreFillColumnFields);
  }

  const isPrefillApplicable = (refField: ICustomFieldDetail) => {
    const exists = props.preFillColumnFields.find(e => e.id === refField.id);
    if (props.preFillColumnFields.length === 1
      && props.preFillColumnFields[0]
      && props.preFillColumnFields[0].id !== refField.id) {
      const { config } = props.preFillColumnFields[0];
      if (config && refField.config) {
        return JSON.stringify(config.data) === JSON.stringify(refField.config.data);
      }
    }
    if (exists && props.preFillColumnFields.length) {
      return true;
    }
    if (!exists && props.preFillColumnFields.length) {
      return false;
    }
    return true;;
  }


  const getRerencedIcon = () => {
    const dataType = props.field.config && props.field.config.dataType;
    const data = props.field.config && props.field.config.data;
    if (dataType === 'document') {
      const formId = data.formId;
      const form = props.publishForm.find(e => e.id === formId);
      if (form && form.icon) {
        return form.icon;
      }
    }
    return icons.blue.datatable;
  }


  let formFieldObj = tableFieldType.filter((field: IFieldTypeObj) => field.defaultData.configName === props.field.configName);
  let icon = icons.blue.font;
  let style = {};

  if (formFieldObj && formFieldObj.length > 0) {
    icon = formFieldObj[0].icon.blue;
    style = formFieldObj[0].style;
  }

  if (props.field.configName === IConfigName.Referenced) {
    icon = getRerencedIcon();
  }

  if (props.field.configName === IConfigName.Autopopulated) {
    icon = icons.blue.autopopulated;
  }

  return <div ref={dropRef} style={{ margin: '7px 0' }}>
    <div ref={dragRef} style={{ ...draggedObject, display: 'flex', alignItems: 'center' }}
      className={`field-type-drag-container ${props.field.configName} ${props.hasDefaultValue && props.hasReferenceField ? 'with-checkbox' : ''}`}>
      {props.hasDefaultValue && props.field.configName === IConfigName.Referenced &&
        <div className={`ui fitted checkbox default-value ${!isPrefillApplicable(props.field) ? 'disabled' : ''}`}
          onClick={() => setpreFillColumnFields(props.field)}>
          <input className="hidden" type="checkbox" onChange={() => { }}
            checked={props.preFillColumnFields.indexOf(props.field) > -1} />
          <label></label>
        </div>
      }
      <div className='field-type-drag-icon'>
        <Image src={icons.blue.dragIcon} />
      </div>
      <Dropdown
        popperContainer={DropdownPopperOption}
        fluid
        selection
        disabled
        icon={null}
        className='attribute-selection'
        value={props.field.configName === IConfigName.Referenced || props.field.configName === IConfigName.Autopopulated ? 'referenced' : 'field'}
        selectOnBlur={false}
        placeholder={'Choose Field or Reference'}
        options={[{ key: 0, text: 'Regular Field', value: 'field' }, { key: 1, text: 'Referenced Field', value: 'referenced' }]}
        onChange={(event, target) => { }}
      />
      <div className={`selected-field`} >
        <div className='field-type-image'>
          <Image src={icon} style={style} />
        </div>
        <div className='field-type-title'>
          <span className='field-title' style={{ cursor: 'pointer' }} onClick={() => props.editTableColumnField(props.field, props.fieldIndex)} >{props.field.label || 'Untitled'}</span>
          {props.field.confidential && <i className="lock icon" style={{ color: '#bdbdbd' }} />}
        </div>
      </div>
      {minWidthInput()}
      {alignmentSelection()}
      {aggregateOptions()}
      <div className='field-type-action'>
        <Image src={icons.blue.delete} onClick={() => props.deleteField(props.fieldIndex)} />
      </div>
    </div>
  </div>
}

export default Field;